import React, { useEffect } from "react";
import { Grid, Typography, Button, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import fileIcon from "../../assets/images/file-icon.svg";
import ApiCalls from "../../api/customAxios";
import Loader from "../Loader";

export default function AdminAccessPage() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    StatusCheck();
  }, []);

  const StatusCheck = () => {
    setIsLoading(true);
    ApiCalls
      .get('enterprises/user/0/1')
      .then((res) => {
        setIsLoading(false);
        navigate("../homepage");
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  return (
    <Grid
      container
      lg={12}
      md={12}
      xs={12}
      className="align-item-center"
      sx={{ py: 10 }}
    >
      <Grid
        item
        lg={7}
        md={8}
        xs={9}
        align="center"
        spacing={4}
        className="m-auto"
        // style={{ paddingBottom: '220px' }}
      >
        {isLoading && <Loader />}
        <Card className="border-1 shadow-0">
          <CardContent>
            <Typography sx={{ mt: 2 }}>
              <img src={fileIcon} width="40px" alt="img"/>
            </Typography>
            <Typography sx={{ my: 2 }} className="f-16 font-weight-600">
            Thank you for registering with Direct sourcing from {localStorage.getItem("appOrigin") === "N" ?  "Neurodiversify.ai" : "hireHQ"}. You
              will be granted access to the enterprise application shortly. If
              your domain is already registered with us, you will be associated
              with that enterprise as a user by your Admin. You will be notified
              once your access is activated. Please come back and refresh.
            </Typography>
            {/*   <Typography className="f-14 gray7">
                            Thank you for your understanding.
                        </Typography>*/}
            <Typography sx={{ my: 2 }}>
              <Button className="btn secondary-button" aria-label="button" onClick={() => window.location.reload(true)} disableRipple="true">
                Refresh
              </Button>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
