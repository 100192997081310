import React from 'react';
import { Grid, Typography, Card, Button, Box, TextField, InputAdornment, Container } from "@mui/material";
const Constant = require("../helpers/constants");

const Footer = () => {
    return (
        <Grid item lg={12} md={12} xs={12} style={{ backgroundColor: "#f7f7fb" }} className="footer-padding">
            <Grid
                container
                lg={12}
                md={12}
                xs={12}
                spacing={1}
                className="footerNav mt-10 justify-center launch-align"
            >
                <Grid item lg={4} md={4} xs={12} className="pl-0 pt-0 footer-responsive">
                    <Typography className="font-weight-600" variant="h6" style={{ marginTop: "20px" }}>
                        <img
                            className="logoImg-header contrasts"
                            // src={(localStorage.getItem("logo") != "null" && localStorage.getItem("logo") != null && localStorage.getItem("logo") != "") ? localStorage.getItem("logo") : process.env.REACT_APP_ORIGIN === "neurodiversify" ? NeuroDiversityLogo : DsaasLogoWhite}
                            src={process.env.REACT_APP_ORIGIN === "neurodiversify" ? Constant.APP_LOGO_NEURODIVERSIFY : Constant.APP_LOGO_GENERAL}
                            alt="Logo"
                            tabIndex="-1"
                            style={{ height: "38px" }}
                        />
                    </Typography>
                    <Box
                        className="small-text-font"
                        style={{ lineHeight: "20px" }}
                    >
                        <Typography className=" text-opacity f-14 gray7">
                        {process.env.REACT_APP_ORIGIN === "neurodiversify" ?
                        "Neurodiversify is a technology solution developed by Simplify to enable businesses to source and engage independent workers and vice-versa.  Both businesses and workers benefit in terms of transparency, time-to-value, quality of fit, and more."
                       : "hireHQ is a technology solution developed to enable businesses to source and engage independent workers – and vice-versa. Both businesses and workers benefit in terms of transparency, time-to-value, quality of fit, and more."}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item lg={1} md={1} xs={12}></Grid>
                <Grid item lg={2} md={2} xs={4} className="center-text mt-20">
                    <Typography className="font-weight-600  f-20" variant="strong">
                        Menu
                    </Typography>
                    <ul className="pl-0">
                        <li>
                            <a href="#home" className="light-black text-opacity f-14 gray7 decorationcolor" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>Home</a>{" "}
                        </li>
                    </ul>
                </Grid>
                <Grid item lg={2} md={2} xs={4} className="center-text mt-20">
                    <Typography className="font-weight-600  f-20" variant="strong">
                        Social
                    </Typography>
                    <ul className="pl-0">
                        <li>
                            <a href="https://www.linkedin.com/company/11181300/" target="_blank"
                                rel="noopener noreferrer"
                                aria-label="LinkedIn link" className="light-black text-opacity f-14 gray7 decorationcolor">LinkedIn</a>{" "}
                        </li>
                        <li>
                            <a href="https://www.facebook.com/simplifyworkforce/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Facebook link" className="light-black text-opacity f-14 gray7 decorationcolor">Facebook</a>{" "}
                        </li>
                        <li>
                            <a href="https://twitter.com/simplifychatter"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Twitter link" className="light-black text-opacity f-14 gray7 decorationcolor">Twitter</a>{" "}
                        </li>
                    </ul>
                </Grid>
                <Grid item lg={1} md={1} xs={4} className="center-text mt-20">
                    <Typography className="font-weight-600  f-20" variant="strong">
                        Legal
                    </Typography>
                    <ul style={{ paddingLeft: "0px" }}>
                        <li>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                tabIndex="0"
                                className="cursor-hover light-black text-opacity f-14 gray7 decorationcolor"
                                href={process.env.REACT_APP_DSAAS_TERMS_OF_USE}
                            >
                                Terms of Use
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                tabIndex="0"
                                className="cursor-hover light-black text-opacity f-14 gray7 decorationcolor"
                                href={process.env.REACT_APP_DSAAS_PRIVACY_POLICY}
                            >
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                tabIndex="0"
                                className="cursor-hover light-black text-opacity f-14 gray7 decorationcolor"
                                href={process.env.REACT_APP_DSAAS_TERMS_OF_USE.replace("termsofuse", "cookiepolicy")}
                            >
                                Cookie Policy
                            </a>
                        </li>
                    </ul>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Footer;
