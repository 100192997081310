
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography, Button, TextareaAutosize, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import paeprClipIcon from "../../assets/images/paper-clip.svg";
import DocumentIcon from "../../assets/images/document.svg";
import deleteIcon from "../../assets/images/delete-new-icon.svg";
import { handleSpaces } from "../helpers/helper-data";
import { browserName, browserVersion } from "react-device-detect";
import { danger, success } from "../helpers/message-box";
import Loader from "../Loader";
import copy from "copy-to-clipboard";
import ApiCalls from "../../api/customAxios";
import Input from "@mui/material/Input";

function FeedBackModal(props) {
    const { open, onClose } = props;
    const currentUrl = window.location.href;
    const appVersion = process.env.REACT_APP_APP_VERSION;
    const [review, setReview] = useState("");
    const hiddenFileInput = React.useRef(null);
    const [ipAddress, setIpAddress] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [browserWidth, setBrowserWidth] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [browserHeight, setBrowserHeight] = useState(null);
    const [subject, setSubject] = useState('');
    const [nameError, setNameError] = useState('');
    const [menuName, setMenuName] = useState();
    const browser = browserName;
    const version = browserVersion;
    const operatingSystem = getOperatingSystem();
    function getOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/win64|wow64/i.test(userAgent)) {
            return "Windows 64-bit";
        }

        if (/win32/i.test(userAgent)) {
            return "Windows 32-bit";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        if (/iphone|ipad|ipod/i.test(userAgent)) {
            return "iOS";
        }

        if (/mac/i.test(userAgent)) {
            return "Mac OS";
        }

        if (/linux/i.test(userAgent)) {
            return "Linux";
        }

        return null;
    };
    useEffect(() => {
        if (open) {
            const fetchLocation = () => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setLatitude(position.coords.latitude);
                            setLongitude(position.coords.longitude);
                        },
                        (error) => {
                        }
                    );
                } else {
                    console.error('Geolocation is not supported by this browser.');
                }
            };
            fetchLocation();
        }
    }, [open]);
    useEffect(() => {
        if (open) {
            const fetchIpAddress = async () => {
                try {
                    const response = await fetch('https://api.ipify.org/?format=json');
                    const data = await response.json();
                    setIpAddress(data.ip);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchIpAddress();
        }
    }, [open]);
    useEffect(() => {
        const handleResize = () => {
              setBrowserWidth(window.innerWidth);
              setBrowserHeight(window.innerHeight);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [open]);
    useEffect(() => {
        getIds(window.location.pathname);
    }, [window.location.pathname]);
    const [isScreenshotTrue, setIsScreenshotTrue] = useState([]);
    const takefile = () => {
        hiddenFileInput.current.value = null;
        hiddenFileInput.current.click();
    };
    let pageTitle = document.title?.split("|")[0];
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded && fileUploaded.type.startsWith('image/')) {
            if (fileUploaded.size <= 1000000) {
                setIsScreenshotTrue([...isScreenshotTrue, fileUploaded]);
            } else {
                danger("Maximum upload size exceeded. Try to upload a file upto 1MB");
            }
        } else {
            danger("Please upload only JPG, JPEG, PNG formats!");
        }
    };
    // html2json
    const TextElem = (e) => ({
        type: 'TextElem',
        textContent: e.textContent
    });
    const fromNode = (e) => {
        switch (e?.nodeType) {
          case 1: return Elem(e);
          case 3: return TextElem(e);
          default: throw Error(`unsupported nodeType: ${e.nodeType}`);
        }
    };
    const Elem = (e) => ({
        type: 'Elem',
        tagName: e.tagName,
        // attributes: Array.from(e.attributes, ({ name, value }) => [name, value]),
        children: Array.from(e.childNodes, fromNode)
    });
    const html2json = (e) => JSON.stringify(Elem(e), null, '  ');
    const getIds = (url) => {
        let id = "";
          if (url.includes('/candidate/homepage')) {
            id = document.getElementById('my-templates');
            setMenuName("Dashboard");
          } else if (url.includes('/candidate/template')) {
            id = document.getElementsByClassName('gjs-cv-canvas__frames')[0];
            setMenuName("Template Editor");
          } else if (url.includes('/candidate/PublishSucess')) {
            id = document.getElementById('publish-sucess');
            setMenuName("Publish");
          }
        return id;
      };
    const handleSubmit = () => {
        setIsLoading(true);
        ApiCalls.get("feedback/exists").then((res) => {
            if (res) {
                const formData = new FormData();
                isScreenshotTrue.forEach((image, index) => {
                    formData.append("attachments", image);
                });
                const requestBody = {
                    browserName: browser,
                    browserVersion: version,
                    appVersion: appVersion,
                    resolution: `${browserWidth}x${browserHeight}`,
                    platform: operatingSystem,
                    application: "hireHQ Career Portal",
                    menuUrl: menuName,
                    feedback: review,
                    subject: subject,
                    ipAddress: ipAddress,
                    userId: localStorage.getItem("client_name"),
                    latitude: latitude,
                    longitude: longitude,
                    formData: html2json(getIds(window.location.pathname)),
                    enterpriseName: localStorage.getItem("MSPName") ? localStorage.getItem("MSPName") : localStorage.getItem("enterprise_name"),
                };
                if (localStorage.getItem("MSPName") != null) {
                    requestBody["mspEnterpriseName"] = localStorage.getItem("enterprise_name");
                };
                formData.append('request-body', new Blob([JSON.stringify(requestBody)], {
                    type: "application/json"
                  }));
                ApiCalls.post("feedback", formData).then((response) => {
                    if (response) {
                        success("Thanks for the feedback");
                        setReview("");
                        setSubject("");
                        setIsScreenshotTrue([]);
                        setIsLoading(false);
                        onClose();
                    }
                }).catch((error)=>{
                    setIsLoading(false);
                    danger("Something went Wrong");
                });
            } else {
                const payload = {
                    Feedback: review,
                    subject: subject,
                    Application: "hireHQ Career Portal",
                    AppVersion: appVersion,
                    Platform: operatingSystem,
                    MenuName: menuName,
                    UserId: localStorage.getItem("candidateId"),
                    FirstName: localStorage.getItem("user_name")?.split(" ")[0],
                    LastName: localStorage.getItem("user_name").split(" ")[1],
                    Email: localStorage.getItem("email"),
                    Enterprise_Name: localStorage.getItem("MSPName") ? localStorage.getItem("MSPName") : localStorage.getItem("enterprise_name"),
                    Latitude: latitude,
            Longitude: longitude,
            IpAddress: ipAddress,
            Resolution: `${browserWidth}x${browserHeight}`,
            BrowserName: browser,
            BrowserVersion: version,
            formData: html2json(getIds(window.location.pathname))
        };
        if (localStorage.getItem("MSPName") != null) {
            payload["MSP_Name"] = localStorage.getItem("enterprise_name");
        };
                const payloadString = Object.entries(payload)
                    .map(([key, value]) => `${key}: ${value}`)
                    .join(", ");
                copy(JSON.stringify(payloadString));
                success("Details are copied to the clipboard");
                setReview("");
                setSubject("");
                setIsScreenshotTrue([]);
                setIsLoading(false);
                onClose();
            }
        });
    };
    const handleDeleteImage = (id) => {
        if (isScreenshotTrue.length > 1) {
            setIsScreenshotTrue(
                isScreenshotTrue.filter((location, _index) => _index !== id)
            );
        } else {
            setIsScreenshotTrue([]);
        }
    };
    const handleReset = () => {
        setReview("");
        setIsScreenshotTrue([]);
    };
    const handleModalClose = () => {
        setReview("");
        setIsScreenshotTrue([]);
        onClose();
        setSubject("");
    };
    if (isLoading) {
        return <Loader />;
    }
    return (
        <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-content align-left inputcontrasts feedback-width">
                <Grid container id="modal-title">
                    <Grid item xs={11}>
                        <Typography
                            className="f-16 font-weight-600 gray9 modalTitle black-heading-color p-l-5"
                            align="left"
                        >
                            Feedback
                        </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: "-10px" }}>
                        <Typography align="right" className="sub-text-color" onClick={handleModalClose}>
                            <CloseIcon className="cursor-hover" />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid id="feedback-modal-description">
                <Grid item lg={6} md={6} xs={6}>
                                    <Box className='m-b-model-label'>
                                        <Typography className='f-14'>
                                            Subject<span className="text-black">*</span>{" "}
                                        </Typography>
                                        <Input
                                            type="text"
                                            id="overview"
                                            name="overview"
                                            value={subject}
                                            inputProps={{
                                                maxLength: 128,
                                              }}
                                            onChange={(event) => {
                                                setSubject(event.target.value);
                                                if (event.target.value.length > 128) {
                                                    setNameError("Exceeded maximum character length of 128.");
                                                } else {
                                                    setNameError("");
                                                }
                                            }}
                                            onKeyPress={handleSpaces}
                                            className={`form-control input-group m-b-0 inputcontrasts ${nameError ? "is-invalid" : ""
                                                }`}
                                            margin="normal"
                                            placeholder="Enter subject"
                                        />
                                    </Box>
                                    <span className="danger-color error-msg">
                                        {" "}
                                        {nameError}
                                    </span>
                                </Grid>
                    <Grid item xs={12} className="mt-20">
                        <Typography className="f-14 mb-5">
                            Tell us about your experience*
                        </Typography>
                        <Typography className="input-focus-none placeholder-text">
                            <TextareaAutosize
                                minRows={2}
                                maxRows={4}
                                aria-label="maximum height"
                                className="form-control input-group inputcontrasts"
                                maxLength={256}
                                inputProps={{ maxLength: 256 }}
                                onChange={(e) => {
                                    if (e.target.value.length === 1) {
                                        setReview(e.target.value.toUpperCase());
                                        return;
                                    }
                                    setReview(e.target.value);
                                }}
                                onKeyPress={handleSpaces}
                                name="review"
                                id="review"
                                value={review}
                                placeholder="Enter feedback"
                                style={{ height: "150px" }}
                            />
                            <Typography
                                align="right"
                                className="sub-text-color sub-text-font"
                            >
                                {review?.length ? review?.length : 0}/256
                            </Typography>
                        </Typography>
                        <Typography>
                            {isScreenshotTrue?.length > 0 && <Typography className="d-flex align-center mb-10" style={{ flexDirection: "column" }}>
                                <>
                                    {isScreenshotTrue?.map((image, key) => {
                                        return <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            xs={12}
                                            key={key}
                                            className="d-flex mt-5  justify-between file-cards p-0 m-l-5"
                                            style={{ width: "100%" }}
                                        >
                                            <Grid
                                                item
                                                lg={10}
                                                md={10}
                                                xs={10}
                                                className="d-flex align-center"
                                            >
                                                {" "}
                                                <img
                                                    src={DocumentIcon}
                                                    alt="img"
                                                    className="m-r-10 ml-10"
                                                    width="18px"
                                                    height="18px"
                                                />
                                                {" "}
                                                <p className="gray7 f-14 textEllipse text-capitalize">
                                                    {image?.name}
                                                </p>{" "}
                                            </Grid>
                                            <Grid
                                                item
                                                lg={2}
                                                md={2}
                                                xs={2}
                                                className="font-13 align-item-right d-flex"
                                            >
                                                <span
                                                    tabIndex={0}
                                                    onKeyPress={(e) => handleDeleteImage(key)}
                                                    className="align-center d-flex sub-text-font m-r-10 cursor-hover gray7"
                                                    onClick={(e) => handleDeleteImage(key)}
                                                >
                                                    <img
                                                        className="sub-text-font"
                                                        src={deleteIcon}
                                                        alt="delete icon"
                                                    />
                                                </span>
                                            </Grid>
                                        </Grid>;
                                    })}
                                </>
                            </Typography>}
                            {isScreenshotTrue?.length <= 1 &&
                                <Typography className="d-flex align-center">
                                    <img src={paeprClipIcon} alt="img" />
                                    <Typography className="cursor-hover f-14 font-weight-400 gray8 m-l-5" onClick={takefile}>Add Screenshot</Typography>
                                    <input type="file" accept="image/jpeg, image/png, image/jpg, image/gif" style={{ display: 'none' }} ref={hiddenFileInput} onChange={handleChange} />
                                </Typography>}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid id="modal-footer" className="justify-end d-flex">
                    <Typography className="d-flex align-center">
                        {/* <Typography className="link-color f-14 font-weight-400 mr-20" onClick={handleReset}>Reset</Typography> */}
                        <Button
                            className="btn primary-button"
                            disabled={!subject?.length || nameError?.length || review == "" ? true : false}
                            onClick={handleSubmit}
                            tabIndex={0}
                            disableRipple="true"
                            aria-label="button"
                        >
                            Submit
                        </Button>
                    </Typography>
                </Grid>
            </Box>
        </Modal>
    );
}

export default FeedBackModal;
